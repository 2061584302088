<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"
          >Compras de {{ user.name }} {{ user.surname }}</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="search"
                clearable
                rounded
                flat
                filled
                hide-details
                :label="`Buscar orden`"
                prepend-inner-icon="fa-search"
                class="d-none d-md-flex"
              />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="headers"
                :items="orders"
                :items-per-page="8"
                sort-by="progressPlacedAt"
                :loading="loading"
                :search="search"
                :sort-desc="true"
                :single-expand="true"
                item-key=".key"
                show-expand
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50, 100, 250],
                }"
              >
                <template v-slot:[`item.businessLogo`]="{ item }">
                  <v-avatar class="mt-3">
                    <v-img
                      v-if="item.businessLogo"
                      :src="item.businessLogo.original"
                      :lazy-src="item.businessLogo.loading"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <img
                      v-if="!item.businessLogo"
                      :src="require('@/assets/user.svg')"
                    />
                  </v-avatar>
                </template>

                <template v-slot:[`item.shortId`]="{ item }">
                  <v-chip>
                    {{ item.shortId }}
                  </v-chip>
                </template>

                <template v-slot:[`item.progressCurrentStatus`]="{ item }">
                  <v-chip :color="getStatusColor(item.progressCurrentStatus)">
                    {{ item.progressCurrentStatus | statusFilter }}
                  </v-chip>
                </template>

                <template v-slot:[`item.orderPaymentMethod`]="{ item }">
                  {{ item.orderPaymentMethod | payMethodFilter }}
                </template>

                <template v-slot:[`item.orderType`]="{ item }">
                  {{ item.orderType | filterOrderType }}
                </template>

                <template v-slot:[`item.progressCreatedAt`]="{ item }">
                  {{ item.progressCreatedAt | filterDate }}
                </template>

                <template v-slot:[`item.orderTotal`]="{ item }">
                  {{ item.orderTotal | currency }}
                </template>

                <template v-slot:[`item.deliveryInfo`]="{ item }">
                  {{ item.deliveryInfo.driverName | capitalize }}
                </template>

                <template v-slot:[`item.progressCompletedAt`]="{ item }">
                  {{ item.progressCompletedAt | filterDate }}
                </template>

                <template v-slot:loading>
                  <div class="ma-5" style="position: relative">
                    <div
                      style="
                        position: absolute;
                        z-index: 999;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <lottie
                        :options="defaultOptions"
                        :height="300"
                        :width="400"
                        v-on:animCreated="handleAnimation"
                      />
                      <p
                        :class="
                          $vuetify.theme.dark
                            ? 'subtitle-1 primary--text'
                            : 'subtitle-1 black--text'
                        "
                      >
                        Cargando Data
                      </p>
                    </div>
                    <v-skeleton-loader
                      ref="skeleton"
                      type="table-tbody"
                      class="mx-auto d-none d-md-block"
                    ></v-skeleton-loader>
                  </div>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="pa-4">
                    <v-card :elevation="0" class="d-flex justify-space-between">
                      <v-card
                        class="text-left px-1 mx-1"
                        max-width="900"
                        min-width="260"
                        :color="
                          $vuetify.theme.dark
                            ? 'rgba(0, 0, 0, 0.4)'
                            : 'rgba(0, 0, 0, 0.1)'
                        "
                        elevation="0"
                        style="border-radius: 17px !important; overflow: hidden"
                      >
                        <v-card-text class="text--primary">
                          <p class="title ma-0" style="color: #e74c3c">
                            Detalles de la Orden
                          </p>
                          <template v-if="item.tinName && item.tinNumber">
                            <v-divider class="my-2"></v-divider>
                            <span class="d-block font-weight-bold text--red"
                              >Con RTN</span
                            >
                            <span style="d-block">{{ item.tinName }}</span>
                            <br />
                            <span style="d-block">{{ item.tinNumber }}</span>
                          </template>
                          <div
                            class="d-block"
                            v-for="(product, itemObjKey) in item.products"
                            :key="
                              product.id
                                ? itemObjKey + 1 + product.id + item['.key']
                                : itemObjKey + 1 + item['.key']
                            "
                          >
                            <v-divider class="my-2"></v-divider>
                            <span class="d-block subtitle-1 font-weight-bold">
                              <v-avatar
                                class="mb-1 mr-1"
                                color="primary"
                                size="21"
                              >
                                <span
                                  class="white--text font-weight-bold"
                                  style="font-size: 15px"
                                  >{{ product.quantity }}</span
                                >
                              </v-avatar>
                              {{ product.name }}
                            </span>
                            <span
                              v-if="product.comments"
                              style="white-space: pre-wrap"
                              >{{ product.comments }}</span
                            >
                            <span v-else class="caption" style="opacity: 0.4"
                              >Producto sin detalles/comentarios</span
                            >
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card>
                  </td>
                </template>

                <template v-slot:[`body.append`]>
                  <tr>
                    <td colspan="12">
                      <v-container>
                        <v-row>
                          <v-col cols="10" sm="10" md="10">
                            <span style="font-weight: bold; font-size: 14px"
                              >Total de órdenes completadas</span
                            >
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-row justify="end">
                              <span
                                style="font-weight: bold; font-size: 14px"
                                >{{ totalAmount }}</span
                              >
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-container>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";

export default {
  name: "view-user-orders",
  props: ["user"],
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      searchableTags: [
        { name: "placed", color: "lime darken-1" },
        { name: "accepted", color: "green" },
        { name: "enRoute", color: "amber" },
        { name: "onDelivery", color: "orange" },
        { name: "delivered", color: "cyan" },
        { name: "completed", color: "blue" },
        { name: "canceled", color: "grey" },
        { name: "rejected", color: "red" },
      ],
      snackbarText: "",
      saving: false,
      orders: [],
      loading: true,
      search: "",
      headers: [
        {
          value: "businessLogo",
        },
        {
          text: "Código",
          value: "shortId",
        },
        {
          text: "Estado",
          value: "progressCurrentStatus",
        },
        {
          text: "Comercio",
          value: "businessName",
          width: "200px",
        },

        {
          text: "Tipo de orden",
          value: "orderType",
          width: "100px",
        },
        {
          text: "Método de pago",
          value: "orderPaymentMethod",
        },

        {
          text: "Creación",
          value: "progressCreatedAt",
          width: "200px",
        },

        {
          text: "Motorista",
          value: "deliveryInfo.driverName",
          width: "250px",
        },

        {
          text: "Completada",
          value: "progressCompletedAt",
          width: "200px",
        },

        {
          text: "Total de la compra",
          value: "orderTotal",
        },
      ],
    };
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY H:mm:ss");
    },

    filterOrderType(value) {
      const orderTypes = {
        airTime: "Recargas electrónicas",
        regular: "Regular",
        pickUp: "Recogida",
        regularPickUp: "Recogida regular",
        none: "N/A",
        errand: "Mandadito",
        "": "N/A",
        atm: "Ocho Cash",
        cargo: "Mandadito",
      };
      return orderTypes[value];
    },

    currency(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      if (amount) return formatter.format(amount);
      else return formatter.format(0);
    },

    payMethodFilter: function (status) {
      switch (status) {
        case "cash":
          return "Efectivo";
          break;

        case "card":
          return "Tarjeta";
          break;

        case "tengo":
          return "Tengo";
          break;
      }
    },

    statusFilter: function (status) {
      switch (status) {
        case "placed":
          return "Colocada por el Cliente";
          break;

        case "accepted":
          return "Aceptada por el Comercio";
          break;

        case "enRoute":
          return "Chavo en Camino al Comercio";
          break;

        case "onDelivery":
          return "Chavo en Camino al Cliente";
          break;

        case "delivered":
          return "Entregada al Cliente";
          break;

        case "completed":
          return "Orden Completada";
          break;

        case "canceled":
          return "Cancelado por Cliente";
          break;

        case "rejected":
          return "Cancelado por administración";
          break;

        default:
          return "Desconocido";
          break;
      }
    },

    capitalize(value) {
      if (value) {
        let arr = value.split(" ");
        let name = "";

        arr.forEach((letter) => {
          name +=
            " " +
            letter.charAt(0).toUpperCase() +
            letter.slice(1).toLowerCase();
        });
        return name;
      } else {
        return "";
      }
    },
  },
  async mounted() {
    let ordersQuery = await db
      .collection("orders")
      .where("userId", "==", this.user[".key"])
      .get();

    let errandsQuery = await db
      .collection("ordersErrands")
      .where("userId", "==", this.user[".key"])
      .get();

    let tempData = [...ordersQuery.docs, ...errandsQuery.docs];

    tempData.forEach((item) => {
      let order = item.data();
      order[".key"] = item.id;
      this.orders.push(order);
    });

    this.loading = false;
  },
  computed: {
    totalAmount: function () {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      var sum = 0;
      this.orders.forEach((e) => {
        if (e.progressCurrentStatus == "completed") sum += e.orderTotal;
      });
      return formatter.format(sum);
    },
  },
  methods: {
    getStatusColor(status) {
      const result = this.searchableTags.find(({ name }) => name === status);
      if (result) {
        return result.color;
      } else {
        return "gray";
      }
    },

    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
  },
};
</script>
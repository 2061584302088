<template>
  <div>
    <v-container fluid class="fill-height">
      <v-row class="pa-0 ma-0">
        <v-col cols="10">
          <h1>
            Órdenes
            <span class="font-weight-light">Programadas</span>
          </h1>
        </v-col>

        <v-col cols="12" md="6">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(date);
                    updateDateFilter();
                  "
                  >Filtrar</v-btn
                >
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="orders"
            :items-per-page="10"
            :loading="loading"
            sort-by="progressPlacedAt"
            :sort-desc="true"
            :single-expand="true"
            :expanded.sync="expanded"
            :search="search"
            item-key=".key"
            show-expand
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template v-slot:[`item.orderPaymentMethod`]="{ item }">
              <v-chip
                :color="colorPaymentMethod(item.orderPaymentMethod)"
                :dark="item.orderPaymentMethod !== 'card'"
                style="
                  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                  cursor: pointer;
                "
              >
                {{ item.orderPaymentMethod | orderPaymentMethodFilter }}
              </v-chip>
            </template>

            <template v-slot:[`item.shortId`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.shortId)"
                  >
                    {{ item.shortId }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.userName`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block; text-transform: capitalize"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.userName)"
                  >
                    {{ item.userName }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.orderType`]="{ item }">
              <span class>{{ item.orderType | orderTypeName }}</span>
            </template>

            <template v-slot:[`item.state`]="{ item }">
              <v-chip
                :color="stateColor(item.state)"
                style="display: inline-block; text-transform: capitalize"
                dark
                text-color="white"
              >
                {{ item.state | filterState }}
              </v-chip>
            </template>

            <template v-slot:[`item.orderTotal`]="{ item }">
              <span class>{{ item.orderTotal | currency }}</span>
            </template>

            <template v-slot:[`item.deliveryCost`]="{ item }">
              <span class>{{ item.deliveryCost | currency }}</span>
            </template>

            <template v-slot:[`item.progressCreatedAt`]="{ item }">
              <span class>{{ item.progressCreatedAt | filterDate }}</span>
            </template>

            <template v-slot:[`item.deliveryTime`]="{ item }">
              <span class>{{ item.deliveryTime | filterDate }}</span>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-4">
                <errand-details
                  v-if="item.orderType == 'errand' || item.orderType == 'cargo'"
                  :item="item"
                />
                <order-details v-else :item="item" />
              </td>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-0 mr-4"
                    fab
                    v-on="on"
                    :disabled="item.state != 'placed'"
                    :dark="$vuetify.theme.dark"
                    x-small
                    @click="accept(item)"
                    :elevation="0"
                    color="primary"
                  >
                    <v-icon dark>fa-check</v-icon>
                  </v-btn>
                </template>
                <span>Colocar orden </span>
              </v-tooltip>
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-dialog
      max-width="600"
      persistent
      v-model="acceptDialog"
      v-if="acceptDialog"
    >
      <accept-order
        @success="handleSuccess"
        :item="selectedOrder"
        @cancel="acceptDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import * as animationData from "@/assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import orderDetails from "../../c19/order-details";
import errandDetails from "../../c19/errand-details";

import acceptOrder from "./accept-order.vue";

export default {
  name: "scheduled-orders",
  components: {
    lottie: Lottie,
    orderDetails,
    errandDetails,
    acceptOrder,
  },
  data() {
    return {
      modal: null,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      agent: null,
      agents: [],
      anim: null,
      animationSpeed: 1.1,
      acceptDialog: false,
      selectedOrder: null,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      dialog: false,
      dialogAccept: false,
      snackbar: false,
      snackbarText: null,
      loading: true,
      formLoading: false,
      orders: [],
      expanded: [],
      itemsPerPageOptions: [4, 8, 12],
      itemsPerPage: 4,
      defaultItem: {},
      editedItem: {},
      itemToCancel: {},
      modalCancel: false,
      disabledCancelOrder: ["rejected"],
      headers: [
        {
          text: "Estado",
          value: "state",
        },
        {
          text: "Id de la Orden",
          value: "shortId",
        },

        {
          text: "Forma del Pago",
          value: "orderPaymentMethod",
        },

        {
          text: "Cliente",
          value: "userName",
        },
        {
          text: "Negocio",
          value: "businessName",
          width: "200px",
        },
        {
          text: "Ciudad",
          value: "cityName",
          // width: "200px",
        },
        {
          text: "Fecha de programación",
          value: "deliveryTime",
          width: "180px",
        },
        {
          text: "Tipo de delivery",
          value: "orderType",
          class: "nowrap",
        },

        {
          text: "Costo del Envío",
          value: "deliveryCost",
        },
        {
          text: "Total de la orden",
          value: "orderTotal",
        },
        {
          text: "Creada el",
          value: "progressCreatedAt",
          filterable: false,
          width: "180px",
        },
        {
          text: "",
          value: "actions",
          align: "end",
          filterable: false,
        },
      ],
    };
  },
  filters: {
    orderTypeName(value) {
      let deliveryType = {
        regular: "Regular",
        pickUp: "Recogida",
        regularPickUp: "Recogida regular",
        none: "Ninguno",
        "": "Desconocido",
      };

      return deliveryType[value];
    },

    filterState(state) {
      let status = {
        canceled: "Cancelada",
        placed: "Pendiente",
        "": "Desconocido",
        success: "Completada",
      };
      return status[state];
    },
    orderPaymentMethodFilter(method) {
      switch (method) {
        case "card":
          return "Tarjeta";
        case "cash":
          return "Efectivo";
        case "tengo":
          return "Tengo";
        case "tigoMoney":
          return "Tigo Money";
        case "dilo":
          return "Dilo";
        case "coupon":
          return "Cupón";
        case "opl":
          return "Ocho Pay";
        default:
          return "Desconocido";
      }
    },
    currency(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },

    filterDate(timestamp) {
      if (!timestamp) return "n/a";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MMM hh:mm A");
    },
  },
  computed: {
    search() {
      return this.$store.state.search;
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  methods: {
    accept(item) {
      this.acceptDialog = true;
      this.selectedOrder = item;
    },

    handleSuccess() {
      this.snackbarText = "Orden colocada exitosamente.";
      this.snackbar = true;
      this.acceptDialog = false;
    },
    stateColor(state) {
      let status = {
        canceled: "red",
        placed: "#bfab00",
        success: "green",
        "": "black",
      };
      return status[state];
    },
    colorPaymentMethod(method) {
      switch (method) {
        case "card":
          return "rgba(0, 0, 0, 0.1)";
        case "cash":
          return "light-green";
        case "tengo":
          return "indigo";
        case "tigoMoney":
          return "#00377b";
        case "dilo":
          return "#f14244";
        case "coupon":
          return "purple";
        case "opl":
          return "#e74c3c";
        default:
          return "black";
      }
    },
    async getOrders() {
      this.loading = true;
      await this.$binding(
        "orders",
        db
          .collection(`scheduledOrders`)
          .where(
            "progressPlacedAt",
            ">",
            moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
          )
          .where(
            "progressPlacedAt",
            "<",
            moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
          )
      );

      this.loading = false;
    },

    title() {
      return "Selecciona las fechas a filtrar";
    },
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }
    },
  },

  watch: {
    date(val) {
      if (val.length > 1) {
        this.getOrders();
      }
    },
  },

  async mounted() {
    this.$store.commit("setSearchTerm", "Órdenes");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    this.getOrders();
  },
};
</script>

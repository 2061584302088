<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <div>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="11" md="11">
          <h1>Cargas masivas</h1>
          <a
            class="ml-5"
            blank
            href="https://firebasestorage.googleapis.com/v0/b/el-ocho-dev.appspot.com/o/Cargas%20masivas%202.0.xlsx?alt=media&token=58455837-711a-4cb3-8f3c-939f0345c9f4"
            >Descargar ejemplo para cargar comercio</a
          >
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="businessId"
            solo-inverted
            clearable
            rounded
            :loading="loading"
            flat
            hide-details
            label="Ingresar ID del comercio"
            prepend-inner-icon="fa-search"
            v-on:keyup.enter="searchBusiness"
            class="d-none d-md-flex"
          />
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-btn
            @click="searchBusiness"
            color="primary"
            dark
            rounded
            class="flex-grow-1 ml-2"
            large
            block
            :elevation="0"
            :minHeight="50"
            >Buscar comercio
          </v-btn>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-btn
            :disabled="!business"
            color="primary"
            dark
            rounded
            class="flex-grow-1 ml-2"
            large
            :elevation="0"
            :minHeight="50"
            block
            @click.prevent="openFileExplorer"
            >Seleccionar archivo
          </v-btn>
        </v-col>

        <v-col v-if="business" cols="12" sm="12" md="12" class="mt-10">
          <span class="font-weight-bold">Resultado</span>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-img
                :src="business.cover.original"
                aspect-ratio="1"
                class="grey lighten-2 cover-up"
                max-width="100%"
                max-height="250"
              >
              </v-img>

              <v-img
                :src="business.logo.original"
                aspect-ratio="1"
                class="grey lighten-2 logo-up"
                max-width="150"
                max-height="150"
              >
              </v-img>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <span>Nombre del comercio</span>
              <p class="title ma-0" style="color: #e74c3c">
                {{ business.shortName }}
              </p>

              <div v-if="business.email">
                <!-- <v-divider class="mt-5"></v-divider> -->
                <span>Correo electrónico</span>
                <p class="title ma-0" style="color: #e74c3c">
                  {{ business.email ? business.email : "" }}
                </p>
              </div>
              <div class="mt-5" v-if="business.phone">
                <span v-if="business.phone">Número teléfono</span>
                <!-- <v-divider class="mt-5"></v-divider> -->
                <p
                  v-if="business.phone"
                  class="title ma-0"
                  style="color: #e74c3c"
                >
                  {{ business.phone }}
                </p>
              </div>

              <!-- <v-divider class="mt-5"></v-divider> -->
              <div class="mt-5">
                <span>Fecha creación</span>
                <p class="title ma-0" style="color: #e74c3c">
                  {{ business.createdAt | filterDate }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <input
        @change="setExcelFile"
        ref="input"
        class="input"
        accept=".xlsx"
        type="file"
      />
    </div>

    <v-dialog
      v-if="modalBusinessData"
      v-model="modalBusinessData"
      max-width="90%"
      persistent
    >
      <file-details
        :selectedColumns="selectedColumns"
        :businessId="business['.key']"
        @cancel="modalBusinessData = false"
        @success="handleSuccess"
        :file="file"
      ></file-details>
    </v-dialog>

    <v-dialog
      v-if="modalStructureExcel"
      v-model="modalStructureExcel"
      max-width="50%"
    >
      <structure-excel
        :businessId="business['.key']"
        @cancel="modalStructureExcel = false"
        @success="handleStructureSuccess"
        :file="file"
      ></structure-excel>
    </v-dialog>
  </v-container>
</template>
<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import fileDetails from "./file-details";
import structureExcel from "./structure-excel";

export default {
  name: "massive-operations",
  components: {
    lottie: Lottie,
    fileDetails,
    structureExcel,
  },
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      snackbarText: "",
      businessId: "",
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      anim: null,
      animationSpeed: 1.1,
      fileName: null,
      file: null,
      modalBusinessData: false,
      business: null,
      modalStructureExcel: false,
    };
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    searchBusiness() {
      if (this.businessId) {
        this.loading = true;
        db.collection("businesses")
          .doc(this.businessId)
          .get()
          .then((res) => {
            this.loading = false;
            if (res.data()) {
              this.business = res.data();
              this.business[".key"] = res.id;
            } else {
              this.snackbarText = "Comercio no encontrado.";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText = "Comercio no encontrado.";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Ingrese el ID del comercio";
        this.snackbar = true;
      }
    },

    openFileExplorer() {
      this.file = null;
      this.fileName = null;

      this.$refs.input.click();
    },

    setExcelFile(e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0];
        this.fileName = e.target.files[0].name;
        this.showBusinessData();
      }
    },
    async showBusinessData() {
      if (this.file) {
        this.modalStructureExcel = true;
      }
    },

    handleSuccess() {
      this.snackbarText = "Productos actualizados exitosamente.";
      this.snackbar = true;
      this.modalBusinessData = false;
      this.businessId = "";
      this.business = null;
    },

    handleStructureSuccess(data) {
      this.selectedColumns = data;
      this.modalStructureExcel = false;
      this.modalBusinessData = true;
    },
  },

  mounted() {
    this.$store.state.visibleSearch = false;
    this.loading = false;
  },
};
</script>


<style scoped>
.input {
  display: none;
}

.file-name {
  font-weight: bold;
  font-size: 15px;
  color: #e74c3c;
}

.logo-up {
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -100px;
  margin-left: 20px;
  cursor: pointer;
}

.cover-up {
  border: 1px solid black;
  border-radius: 10px;
}
</style>
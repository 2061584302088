<template>
  <div>
    <v-snackbar absolute top :timeout="4000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">
          {{ user.length > 0 ? "Editar" : "Buscar" }} cliente
        </span>
        <v-btn
          style="right:10px; top:10px;"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container v-if="user.length == 0" fluid>
          <v-row>
            <v-col cols="12">
              <h2>Ingrese el número celular del cliente</h2>
              <p>Asegurate que sea el mismo número que registró en ocho</p>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="countryCode"
                :items="codes"
                outlined
                rounded
                class="mt-2"
              />
            </v-col>
            <v-col cols="12" md="8">
              <input
                :loading="loading"
                :disabled="loading"
                class="control-input control-input-number"
                type="number"
                placeholder="Ingrese el número de celular"
                v-model.number="newUser.phone"
              />
            </v-col>

            <v-col v-if="userNotFound" cols="12">
              <v-alert type="error">
                El número ingresado no existe en nuestros registros, favor
                ingrese el número correctamente o solicite al dueño del número
                que descargue Ocho y se registre en el app
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              :loading="loading"
              @click="searchUser()"
              height="50px"
              block
              class="save-btn mt-4"
              color="primary"
            >
              Buscar
            </v-btn>
          </v-row>
        </v-container>
        <v-container v-else fluid>
          <v-row>
            <v-col cols="12">
              <p>Nombre del cliente</p>
              <span class="font-weight-bold">
                {{ user[0].name }} {{ user[0].surname }}
              </span>
            </v-col>

            <v-col cols="12">
              <p>Celular del cliente</p>
              <span class="font-weight-bold">
                {{ user[0].phone }}
              </span>
            </v-col>

            <v-col cols="12">
              <p>Email del cliente</p>
              <span class="font-weight-bold">
                {{ user[0].email }}
              </span>
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="12">
              <p>Escoja el listado que desea asociar al cliente</p>
              <v-select
                v-model="newUser.priceList"
                :items="priceLists"
                placeholder="Seleccione el listado de precios"
                outlined
                :loading="loading"
                item-value="value"
                item-text="name"
                rounded
                class="mb-0 pb-0"
              />
            </v-col>

            <v-col cols="12">
              <p>Código del cliente en su sistema</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese el código del cliente"
                v-model="newUser.code"
              />
            </v-col>

            <v-col cols="12">
              <p>Nombre del negocio del cliente</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese el nombre del negocio del cliente"
                v-model="newUser.business"
              />
            </v-col>

            <v-col cols="12">
              <p>Número registro fiscal del negocio del cliente</p>
              <input
                class="control-input control-input-number"
                type="number"
                placeholder="Ingrese el número registro fiscal"
                v-model.number="newUser.tin"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              v-if="type == 'new'"
              block
              @click="saveUser()"
              class="save-btn mt-4"
              color="primary"
              >Guardar</v-btn
            >
            <v-btn
              v-else
              block
              @click="updateUser()"
              class="save-btn mt-4"
              color="primary"
              >Guardar</v-btn
            >
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "add-customer",
  props: ["type", "customers", "userToEdit"],
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      user: [],
      priceLists: [
        { name: "Lista 1", value: "priceList1" },
        { name: "Lista 2", value: "priceList2" },
        { name: "Lista 3", value: "priceList3" },
        { name: "Lista 4", value: "priceList4" },
        { name: "Lista 5", value: "priceList5" },
        { name: "Lista 6", value: "priceList6" },
        { name: "Lista 7", value: "priceList7" },
        { name: "Lista 8", value: "priceList8" },
      ],
      userNotFound: false,
      newUser: {
        active: true,
        business: "",
        code: "",
        deleted: false,
        email: "",
        modifiedAt: "",
        modifiedBy: "",
        name: "",
        phone: "",
        tin: "",
        createdBy: this.$store.state.user[".key"],
      },
      saving: false,
      loading: false,
      randomKey: 0,
      countryCode: "+504",
      codes: ["+504", "+502"],
    };
  },
  watch: {
    "newUser.phone"() {
      this.userNotFound = false;
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),

    validate() {
      if (!this.newUser.priceList) {
        this.snackbarText = "Seleccione el listado de precios";
        this.snackbar = true;
        return false;
      } else if (!this.newUser.business) {
        this.snackbarText = "Ingrese el nombre del negocio";
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    moment,
    saveUser() {
      if (this.validate) {
        this.newUser.name = `${this.user[0].name ? this.user[0].name : ""} ${
          this.user[0].surname ? this.user[0].surname : ""
        }`;
        this.newUser.email = this.user[0].email ? this.user[0].email : "";
        this.newUser.createdAt = new Date();
        this.newUser.phone = `${this.countryCode}${this.newUser.phone}`;
        this.newUser.userId = this.user[0][".key"];
        this.saving = true;

        db.collection(`businesses/${this.selectedBusiness[".key"]}/customers`)
          .add(this.newUser)
          .then((ref) => {
            this.saving = false;
            this.$emit("success", "Usuario vinculado exitosamente.");

            this.createLedger({
              source_id: ref.id,
              source_type: "customers",
              currentValue: this.newUser,
              prevValue: {},
            });
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } else {
        return;
      }
    },
    updateUser() {
      if (this.validate) {
        this.newUser.modifiedAt = new Date();
        this.saving = true;
        let userId = this.newUser[".key"];
        delete this.newUser[".key"];

        db.collection(`businesses/${this.selectedBusiness[".key"]}/customers`)
          .doc(userId)
          .update(this.newUser)
          .then(() => {
            this.saving = false;
            this.$emit("success", "Usuario actualizado exitosamente.");

            this.createLedger({
              source_id: userId,
              source_type: "customers",
              currentValue: this.newUser,
              prevValue: this.userToEdit,
            });
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } else {
        return;
      }
    },
    async searchUser() {
      if (
        this.customers.find(
          (user) => user.phone === `${this.countryCode}${this.newUser.phone}`
        )
      ) {
        this.snackbarText =
          "Este usuario ya existe en su listado de clientes preferenciales ";
        this.snackbar = true;
        return false;
      } else if (this.newUser.phone) {
        this.loading = true;

        let user = this.$binding(
          "user",
          db
            .collection("users")
            .where("phone", "==", `${this.countryCode}${this.newUser.phone}`)
        ).then((user) => {
          this.loading = false;
          if (user.length == 0) {
            this.userNotFound = true;
          }
        });

        await Promise.all([user]);
      } else {
        this.snackbarText = "Ingrese el número de celular";
        this.snackbar = true;
      }
    },
  },
  mounted() {
    if (this.type == "edit") {
      this.user.push(this.userToEdit);
      this.newUser = Object.assign({}, this.userToEdit);
    }
  },
};
</script>

<style>
.category-color {
  -webkit-appearance: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  height: 45px !important;
  cursor: pointer;
}
.category-color::-webkit-color-swatch {
  border-radius: 50px;
  padding: 0;
}
.category-color::-webkit-color-swatch-wrapper {
  border-radius: 50px;
  padding: 0;
}
.select-city {
  border-radius: 50px;
  padding: 0;
}
.control-input {
  border-color: rgba(117, 117, 117, 0.582);
}
.ad-img-promo {
  max-width: 100%;
  min-width: 100%;
  max-height: 250px;
  border-radius: 20px;
  cursor: pointer;
  object-fit: cover;
}
.img-container-promo {
  max-width: 100%;
  min-width: 100%;
  max-height: 200px;
  min-height: 200px;
  cursor: pointer;
  background-color: #92929221;
  border: 1px solid #80808062;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.time-picker .ant-time-picker-input {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  color: black;
  height: 44px;
}
.upload {
  display: none;
}
.v-tabs:not(.v-tabs--vertical) .v-tab {
  color: black !important;
  font-weight: bold;
}
.v-tab--active {
  border-bottom: 4px solid #e74c3c !important;
}
</style>

<template>
  <div>
    <v-container fluid class="fill-height">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="12" md="8">
          <h1>
            Órdenes
            <span class="font-weight-light">Fallidas</span>
          </h1>
        </v-col>

        <v-col class="pr-5" cols="12" md="4" sm="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(date);
                    updateDateFilter();
                  "
                >
                  Filtrar
                </v-btn>
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-data-table
            :headers="headers"
            :items="orders"
            :items-per-page="10"
            :loading="loading"
            sort-by="date"
            :sort-desc="true"
            :search="search"
            item-key=".key"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ item.date | filterDate }}
            </template>
            <template v-slot:[`item.orderData.orderPaymentMethod`]="{ item }">
              <v-chip
                v-if="item.orderData.orderPaymentMethod"
                :color="colorPaymentMethod(item.orderData.orderPaymentMethod)"
              >
                {{ item.orderData.orderPaymentMethod | filterPaymentMethod }}
              </v-chip>
            </template>

            <template v-slot:[`item.orderData.orderTotal`]="{ item }">
              {{ item.orderData.orderTotal | currency }}
            </template>

            <template v-slot:[`item.orderData.userId`]="{ item }">
              <v-tooltip v-if="item.orderData.userId" right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.orderData.userId)"
                  >
                    {{ item.orderData.userId }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.orderData.userPhone`]="{ item }">
              <v-tooltip v-if="item.orderData.userPhone" right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.orderData.userPhone)"
                  >
                    {{ item.orderData.userPhone }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.orderData.shortId`]="{ item }">
              <v-tooltip v-if="item.orderData.shortId" right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.orderData.shortId)"
                  >
                    {{ item.orderData.shortId }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-1"
                    fab
                    @click="viewCard(item)"
                    v-on="on"
                    :dark="$vuetify.theme.dark"
                    :disabled="item.orderData.orderPaymentMethod != 'card'"
                    x-small
                    :elevation="0"
                    color="gray"
                  >
                    <v-icon dark>fas fa-credit-card</v-icon>
                  </v-btn>
                </template>
                <span>Información de la Tarjeta</span>
              </v-tooltip> -->

              <v-btn @click="editCardsF(item)" small color="primary"
                >Tarjetas</v-btn
              >
              <v-btn
                class="ml-3"
                @click="viewOrdersF(item)"
                small
                color="primary"
                >Compras</v-btn
              >
              <v-btn
                class="ml-3"
                @click="viewAdressesF(item)"
                small
                color="primary"
                >Direcciones</v-btn
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <!-- <v-dialog max-width="700" v-if="cardDialog" persistent v-model="cardDialog">
      <card :item="selectedItem" @cancel="cardDialog = false"></card>
    </v-dialog> -->

    <v-dialog v-if="cardListDialog" v-model="cardListDialog" max-width="90%">
      <edit-cards
        @cancel="cardListDialog = false"
        :key="selectedUser['.key']"
        :usedCard="selectedItem.orderData.orderPaymentToken"
        :user="selectedUser"
      ></edit-cards>
    </v-dialog>

    <v-dialog
      v-if="viewOrdersDialog"
      v-model="viewOrdersDialog"
      max-width="90%"
    >
      <user-orders
        @cancel="viewOrdersDialog = false"
        :key="selectedUser['.key']"
        :user="selectedUser"
      ></user-orders>
    </v-dialog>

    <v-dialog
      v-if="dialogViewAddresses"
      v-model="dialogViewAddresses"
      max-width="90%"
    >
      <user-addresses
        @cancel="dialogViewAddresses = false"
        :key="selectedUser['.key']"
        :user="selectedUser"
      ></user-addresses>
    </v-dialog>
  </div>
</template>
<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";

import userOrders from "../users/user-orders";
import editCards from "../users/edit-cards";
import userAddresses from "../users/user-addresses";

import { mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import "moment-duration-format";

import card from "./card";

export default {
  name: "dropped-orders",
  components: {
    lottie: Lottie,
    card,
    editCards,
    userOrders,
    userAddresses,
  },
  mixins: [lottieSettings],
  data() {
    return {
      modal: null,
      selectedItem: null,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],

      snackbar: false,
      snackbarText: null,
      loading: true,
      orders: [],
      cardDialog: false,
      cardListDialog: false,
      itemsPerPageOptions: [4, 8, 12],
      itemsPerPage: 4,
      selectedUser: null,
      viewOrdersDialog: false,
      dialogViewAddresses: false,
      headers: [
        {
          text: "ID de la orden",
          value: "orderData.shortId",
          align: "center",
          active: true,
          // class: "nowrap",
        },
        {
          text: "Comercio",
          value: "orderData.businessName",
          align: "center",
          active: true,
          // class: "nowrap",
          width: "200px",
        },
        {
          text: "Id de usuario",
          value: "orderData.userId",
          align: "center",
          active: true,
          // class: "nowrap",
        },
        {
          text: "Número de teléfono",
          value: "orderData.userPhone",
          align: "center",
          active: true,
          // class: "nowrap",
        },
        {
          text: "Cliente",
          value: "orderData.userName",
          align: "center",
          active: true,
          // class: "nowrap",
          width: "200px",
        },
        {
          text: "Forma del Pago",
          value: "orderData.orderPaymentMethod",
          align: "center",
          active: true,
          // class: "nowrap",
        },

        {
          text: "Total de la Orden",
          value: "orderData.orderTotal",
          align: "center",
          active: true,
          // class: "nowrap",
        },

        {
          text: "Error",
          value: "error",
          align: "center",
          active: true,
          // class: "nowrap",
          width: "300px",
        },
        {
          text: "Fecha creación",
          active: true,
          value: "date",
          // class: "nowrap",
        },
        {
          // text: "",
          active: true,
          value: "actions",
          // class: "nowrap",
          width: "350px",
        },
      ],
    };
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY hh:mm A");
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount ? amount : 0);
    },

    filterPaymentMethod(method) {
      switch (method) {
        case "card":
          return "Tarjeta";
        case "cash":
          return "Efectivo";
        case "tengo":
          return "Tengo";
        case "tigoMoney":
          return "Tigo Money";
        case "dilo":
          return "Dilo";
        case "coupon":
          return "Cupón";
        case "opl":
          return "Ocho Pay";
        default:
          return "Desconocido";
      }
    },
  },
  //   watch: {
  //     async date(val) {
  //       this.getData();
  //     },
  //   },
  computed: {
    ...mapState(["user"]),
    search() {
      return this.$store.state.search;
    },

    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  methods: {
    viewCard(card) {
      this.selectedItem = card;
      this.cardDialog = true;
    },

    async editCardsF(card) {
      this.selectedItem = card;
      await this.$binding(
        "selectedUser",
        db.collection("users").doc(card.userId)
      );

      this.cardListDialog = true;
    },

    async viewOrdersF(card) {
      await this.$binding(
        "selectedUser",
        db.collection("users").doc(card.userId)
      );

      this.viewOrdersDialog = true;
    },

    async viewAdressesF(card) {
      await this.$binding(
        "selectedUser",
        db.collection("users").doc(card.userId)
      );

      this.dialogViewAddresses = true;
    },

    async getData() {
      let orderCollection = db.collection(`droppedOrders`);
      this.loading = true;
      this.orders = [];

      let ordersQuery = orderCollection
        .where(
          "date",
          ">",
          moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "date",
          "<",
          moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
        );

      await this.$binding("orders", ordersQuery);
      this.loading = false;
    },

    colorPaymentMethod(method) {
      switch (method) {
        case "card":
          return "rgba(0, 0, 0, 0.1)";
        case "cash":
          return "light-green";
        case "tengo":
          return "indigo";
        case "tigoMoney":
          return "#00377b";
        case "dilo":
          return "#f14244";
        case "coupon":
          return "purple";
        case "opl":
          return "#e74c3c";
        default:
          return "black";
      }
    },
    title() {
      return "Selecciona las fechas a filtrar";
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }

      this.getData();
    },
  },

  async mounted() {
    this.loading = true;
    this.$store.commit("setSearchTerm", "Órdenes");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    this.getData();
  },
  destroyed() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
};
</script>
<template>
  <v-dialog persistent v-model="modalNewPayment" max-width="70%">
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-card>
      <v-card-title class="grid-close">
        <v-btn
          icon
          v-if="orderSelected && !completed"
          color="primary"
          style="border-radius: 50px; border: 1px solid #e74c3c78"
          fab
          @click="
            orderSelected = false;
            order = {};
          "
        >
          <v-icon style="font-size: 24px !important" dark
            >fas fa-long-arrow-alt-left</v-icon
          >
        </v-btn>

        <span v-if="!completed" class="headline ml-2">{{
          orderSelected ? "Confirmar reversión" : "Seleccionar orden a revertir"
        }}</span>
        <span v-if="completed" class="headline ml-2">Reversión completa</span>

        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="cancel"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
        <v-text-field
          v-model="search"
          v-if="!orderSelected"
          clearable
          :loading="loading"
          rounded
          flat
          filled
          hide-details
          :label="`Buscar orden por usuario, ID o nombre del comercio`"
          prepend-inner-icon="fa-search"
          style="width: 100%"
          class="d-none d-md-flex mt-5 mb-0"
        />
      </v-card-title>
      <v-card-text
        style="max-height: 60vh; min-height: 40vh; overflow-y: scroll"
      >
        <v-snackbar top :timeout="3000" v-model="snackbar">{{
          snackbarText
        }}</v-snackbar>
        <v-container fluid>
          <v-overlay class="loading-center" :value="saving">
            <lottie
              :options="defaultOptions"
              :height="300"
              :width="400"
              v-on:animCreated="handleAnimation"
            />
          </v-overlay>

          <v-row>
            <v-col v-if="!orderSelected" cols="12" md="12" sm="12">
              <v-data-table
                :headers="headersOrders"
                :items="orders"
                sort-by="progressPlacedAt"
                :search="search"
                :items-per-page="10"
                :loading="loading"
                :sort-desc="true"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50, 100, 250],
                }"
              >
                <template v-slot:[`item.options`]="{ item }">
                  <v-btn
                    @click="setOrder(item)"
                    small
                    color="primary"
                    class="mr-3"
                    >Seleccionar</v-btn
                  >
                </template>
                <template v-slot:[`item.shortId`]="{ item }">
                  <v-chip>
                    {{ item.shortId }}
                  </v-chip>
                </template>
                <template v-slot:[`item.orderTotal`]="{ item }">
                  {{ item.orderTotal | currency }}
                </template>
                <template v-slot:[`item.progressCreatedAt`]="{ item }">
                  {{ item.progressCreatedAt | filterDate }}<br />
                  <span
                    :class="
                      $vuetify.theme.dark
                        ? 'grey--text'
                        : 'grey--text text--darken-1'
                    "
                    >{{ item.progressCreatedAt | formatDate }}</span
                  >
                </template>

                <template v-slot:loading>
                  <div class="ma-5" style="position: relative">
                    <div
                      style="
                        position: absolute;
                        z-index: 999;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <lottie
                        :options="defaultOptions"
                        :height="300"
                        :width="400"
                        v-on:animCreated="handleAnimation"
                      />
                      <p
                        :class="
                          $vuetify.theme.dark
                            ? 'subtitle-1 primary--text'
                            : 'subtitle-1 black--text'
                        "
                      >
                        Cargando Data
                      </p>
                    </div>
                    <v-skeleton-loader
                      ref="skeleton"
                      type="table-tbody"
                      class="mx-auto d-none d-md-block"
                    ></v-skeleton-loader>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row v-if="orderSelected && !completed">
            <v-col>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <div class="container-details">
                    <p class="font-weight-bold">Detalles del cliente</p>
                    <v-divider class="mb-3"></v-divider>
                    <span class="font-weight-bold">Nombre del cliente</span>
                    <p>{{ order.userName }}</p>
                    <span class="font-weight-bold">Correo electrónico</span>
                    <p>{{ order.userEmail }}</p>
                    <span class="font-weight-bold">Número de teléfono</span>
                    <p>{{ order.userPhone }}</p>
                  </div>
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <div class="container-details">
                    <p class="font-weight-bold">Detalles de la orden</p>
                    <v-divider class="mb-3"></v-divider>
                    <span class="font-weight-bold">ID de la orden</span>
                    <p>{{ order.shortId }}</p>
                    <span class="font-weight-bold">Nombre del comercio</span>
                    <p>{{ order.businessName }}</p>
                    <span class="font-weight-bold">Total de la orden</span>
                    <p>
                      {{ order.orderTotal | currency }}
                      <v-menu
                        v-model="menuEdit"
                        :close-on-content-click="false"
                        :nudge-width="200"
                        offset-x
                        style="border-radius: 20px"
                      >
                        <template v-slot:activator="{ on }">
                          <i
                            @click="newOrderTotal = order.orderTotal"
                            title="Clic para cambiar el monto a cobrar"
                            v-on="on"
                            class="far fa-edit ml-3 edit"
                          ></i>
                        </template>

                        <div class="change-order-total">
                          <h2 class="mt-4 font-weight-bold">
                            Total de la orden
                          </h2>
                          <v-divider class="mb-3"></v-divider>
                          <input
                            class="control-input control-input-number"
                            type="number"
                            placeholder="Ingrese la posición"
                            v-model.number="newOrderTotal"
                          />
                          <v-btn
                            color="primary"
                            block
                            class="mt-5"
                            @click="changeOrderTotal"
                          >
                            Aceptar
                          </v-btn>
                        </div>
                      </v-menu>
                    </p>
                  </div>
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <div class="container-details">
                    <p class="font-weight-bold">Detalles de la tarjeta</p>
                    <v-divider class="mb-3"></v-divider>
                    <span class="font-weight-bold">Nombre del titular</span>
                    <p>{{ card.name }}</p>
                    <span class="font-weight-bold">Número de tarjeta</span>
                    <p>{{ card.tokens.bac }}</p>
                    <span class="font-weight-bold">Válida hasta</span>
                    <p>{{ cardDate }}</p>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="ma-0 pa-0 mt-8"></v-divider>
              <v-row align="center" justify="end">
                <v-col class="ma-0 pa-0 mt-5" cols="12" sm="4" md="4">
                  <v-btn color="primary" block @click="reversals()">
                    Ejecutar reversión
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-10" v-if="completed">
            <v-col
              style="text-align: center; font-size: 20px"
              sm="12"
              md="12"
              cols="12"
            >
              <i
                v-if="success"
                style="font-size: 40px; color: green"
                class="fas fa-check"
              ></i>
              <i
                v-if="!success"
                style="font-size: 40px; color: red"
                class="fas fa-times"
              ></i>
              <p style="font-size: 25px" class="mt-10">
                {{
                  success
                    ? "Reversión realizada correctamente 😀"
                    : "Ocurrió un error al realizar la reversión 😫"
                }}
              </p>
              <!-- <p v-if="!success"> Error {{payResponse.data.reasonCode}} : {{ payResponse.data.reasonCodeDescription}} </p> -->
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import axios from "axios";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "HNL",
  minimumFractionDigits: 2,
});

export default {
  name: "reversals",
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      search: "",
      snackbarText: "",
      menuEdit: false,
      success: false,
      completed: false,
      card: [],
      orders: [],
      order: [],
      newOrderTotal: 0,
      orderSelected: false,

      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      modalNewPayment: true,
      anim: null,
      animationSpeed: 1.1,
      payResponse: null,
      headersOrders: [
        {
          text: "Order ID",
          value: "shortId",
        },
        {
          text: "Cliente",
          value: "userName",
          width: "200px",
        },

        {
          text: "Negocio",
          value: "businessName",
          width: "250px",
        },
        {
          text: "Estado del pago",
          value: "orderPaymentStatus",
        },

        {
          text: "Fecha",
          value: "progressCreatedAt",
          width: "200px",
        },
        {
          text: "Monto Total",
          value: "orderTotal",
        },

        { value: "options" },
      ],
    };
  },

  watch: {
    order() {
      if (this.order.businessName) {
        this.$binding(
          "card",
          db
            .collection("users")
            .doc(this.order.userId)
            .collection("cards")
            .doc(this.order.orderPaymentToken)
        ).then((card) => {
          this.orderSelected = true;
        });
      }
    },
  },

  filters: {
    currency(amount) {
      return formatter.format(amount);
    },

    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").fromNow();
    },

    formatDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },

  computed: {
    getIp() {
      return new Promise((resolve, reject) => {
        axios.get("https://api.ipify.org/?format=json").then((res) => {
          resolve(res.data.ip);
        });
      });
    },

    cardDate() {
      return moment(this.card.validThru.toDate())
        .tz("America/Tegucigalpa")
        .format("MM/YY");
    },
    getAmount: {
      get: function () {
        return formatter.format(this.order.orderTotal);
      },
      set: function (newValue) {
        this.order.orderTotal = newValue;
      },
    },
  },
  methods: {
    changeOrderTotal() {
      this.order.orderTotal = this.newOrderTotal;
      this.menuEdit = false;
    },
    cancel() {
      this.$emit("cancel");
    },

    handleSuccess(response) {
      db.collection("orders")
        .doc(this.order[".key"])
        .update({
          orderPaymentStatus: "reversed",
        })
        .then(() => {
          this.saving = false;
          this.success = true;
          this.completed = true;
        });
    },

    handleFail(err) {
      this.completed = true;
      this.success = false;
      this.saving = false;
    },

    async reversals() {
      if (this.order.orderTotal && this.order.shortId) {
        this.saving = true;

        let data = {
          amount: this.order.orderTotal,
          orderNumber: this.order.shortId,
          modificationType: 3,
        };

        axios({
          method: "POST",
          crossDomain: true,
          url: `${process.env.VUE_APP_FUNCTIONS_URL}/httpPaymentGatewayBacTransactionModification`,
          data,
        })
          .then((res) => {
            this.handleSuccess(res);
          })
          .catch((err) => {
            this.handleFail(err);
          });
      } else {
        this.snackbarText = "Debe completar todo los campos.";
        this.snackbar = true;
      }
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    setOrder(order) {
      this.order = order;
    },
  },

  mounted() {
    let query = db
      .collection(`orders`)
      .where(
        "progressPlacedAt",
        ">",
        moment(
          new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)
            .toISOString()
            .substr(0, 10),
          "YYYY-MM-DD"
        )
          .startOf("day")
          .toDate()
      )
      .where(
        "progressPlacedAt",
        "<",
        moment(moment().toDate().toISOString().substr(0, 10), "YYYY-MM-DD")
          .endOf("day")
          .toDate()
      )
      .where("orderPaymentMethod", "==", "card")
      .where("orderPaymentStatus", "==", "pendingCapture");

    this.$binding("orders", query).then((orders) => {
      this.orders = this.orders.filter(
        (order) =>
          order.progressCurrentStatus == "placed" ||
          order.progressCurrentStatus == "accepted"
      );
      this.loading = false;
    });
  },
};
</script>


<style>
.select-city {
  border-radius: 50px;
  padding: 0;
}

.card {
  width: 100%;
  background-color: rgba(53, 51, 50, 0.18);
  border-radius: 20px;
  padding: 10px;
  /* height: 87px; */
}

.stepper {
  cursor: pointer;
}

.container-details {
  background-color: #c5c0c042;
  border-radius: 10px;
  padding: 15px;
}

.edit {
  cursor: pointer;
  color: gray;
}

.change-order-total {
  width: 400px;
  padding: 15px;
  height: 220px;
  background-color: white;
  border-radius: 20px;
  /* -webkit-box-shadow: 3px 1px 9px 0px rgba(204,132,106,1);
    -moz-box-shadow: 3px 1px 9px 0px rgba(204,132,106,1);
    box-shadow: 3px 1px 9px 0px rgba(204,132,106,1); */
}
</style>
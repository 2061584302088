<template>
  <v-container fluid>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col style="display: flex; align-items: center" cols="12" md="3">
        <h1 class="ma-0 pa-0">Comercios</h1>
      </v-col>

      <v-col cols="12" md="4">
        <v-autocomplete
          v-model="selectedCities"
          :items="cities"
          @change="getBusinesses"
          prepend-icon="fa-city"
          label="Seleccionar ciudad"
          item-text="name"
          item-value=".key"
          aria-autocomplete="false"
          autocomplete="off"
          hide-details
          filled
          rounded
          multiple
          :allow-overflow="false"
          clearable
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="4">
        <v-autocomplete
          v-model="selectedExecutive"
          :items="executives"
          prepend-icon="fa-user"
          label="Seleccionar ejecutivo encargado"
          item-text="name"
          item-value=".key"
          aria-autocomplete="false"
          autocomplete="off"
          hide-details
          filled
          rounded
          :allow-overflow="false"
          clearable
        >
        </v-autocomplete>
      </v-col>

      <v-col style="display: flex; justify-content: flex-end" cols="12" md="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              fab
              v-on="on"
              class="mr-1"
              @click="toExcel"
              :disabled="
                !filterBusinessesByCity || !filterBusinessesByCity.length
              "
              dark
              :elevation="0"
              :minHeight="56"
              :loading="loading"
            >
              <v-icon x-large dark>fa-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Descargar .xlsx</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row justify-md="end" class="mx-8 mx-md-0">
          <!-- <v-btn
            :block="isMobile"
            :disabled="!selectedCities.length"
            color="primary"
            class="ma-2 white--text"
            v-if="actionsRoles.includes(user.type)"
            @click="showFeaturedBusinesses()"
            :loading="loading"
          >
            <v-icon right dark class="mr-3"> fas fa-flag </v-icon>
            Actualizar flags
          </v-btn> -->
          <v-btn
            :block="isMobile"
            color="primary"
            :disabled="!selectedCities.length"
            class="ma-2 white--text"
            @click="showAddBusiness()"
            v-if="actionsRoles.includes(user.type)"
            :loading="loading"
          >
            <v-icon right dark class="mr-3"> fas fa-plus </v-icon>
            Crear nuevo comercio
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="getHeader"
          :items="filterBusinessesByCity"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          sort-by="createdAt"
          :sort-desc="true"
          item-key=".key"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.imagen`]="{ item }">
            <v-avatar
              class="mt-3"
              v-if="item.picture != 'assets/imgs/avatar-default.svg'"
            >
              <v-img
                style="object-fit: cover"
                :src="item.logo.original"
                :alt="item.name"
                :lazy-src="item.logo.loading"
              >
              </v-img>
            </v-avatar>
          </template>

          <template v-slot:[`item.shortName`]="{ item }">
            <div @click="editBusiness(item)">
              {{ item.shortName }}
            </div>
          </template>

          <template v-slot:[`item.businessId`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item['.key'])"
                >
                  {{ item['.key'] || '0000-0000' }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.phone`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.phone)"
                >
                  {{ item.phone || '0000-0000' }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.isFather`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { isFather: item.isFather },
                  item['.key'],
                  item,
                  'isFather'
                )
              "
              v-model="item.isFather"
            ></v-switch>
          </template>

          <template v-slot:[`item.featured`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { featured: item.featured },
                  item['.key'],
                  item,
                  'featured'
                )
              "
              v-model="item.featured"
            ></v-switch>
          </template>

          <template v-slot:[`item.ochoActive`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { ochoActive: item.ochoActive },
                  item['.key'],
                  item,
                  'ochoActive'
                )
              "
              v-model="item.ochoActive"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.isComingSoon`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { isComingSoon: item.isComingSoon },
                  item['.key'],
                  item,
                  'isComingSoon'
                )
              "
              v-model="item.isComingSoon"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item,
                  'active'
                )
              "
              v-model="item.active"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.closed`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { closed: item.closed },
                  item['.key'],
                  item,
                  'closed'
                )
              "
              v-model="item.closed"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.isForceClosed`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { isForceClosed: item.isForceClosed },
                  item['.key'],
                  item,
                  'isForceClosed'
                )
              "
              v-model="item.isForceClosed"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.isCongested`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { isCongested: item.isCongested },
                  item['.key'],
                  item,
                  'isCongested'
                )
              "
              v-model="item.isCongested"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.isTestMode`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { isTestMode: item.isTestMode },
                  item['.key'],
                  item,
                  'isTestMode'
                )
              "
              v-model="item.isTestMode"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.isFreeDelivery`]="{ item }">
            <v-switch
              :loading="loading"
              @change="handleFreeDelivery(item)"
              v-model="item.isFreeDelivery"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | filterDate }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <div class="options-flex">
              <v-btn
                @click="editBusiness(item)"
                small
                color="primary"
                class="my-2"
              >
                Editar
              </v-btn>

              <v-btn
                @click="clone(item)"
                v-if="actionsRoles.includes(user.type)"
                class="ml-3 my-2"
                small
                color="primary"
              >
                Clonar
              </v-btn>

              <v-btn
                :disabled="!item.isFather == true"
                @click="addChildren(item)"
                class="ml-3 my-2"
                v-if="actionsRoles.includes(user.type)"
                small
                color="primary"
              >
                Asignar comercios
              </v-btn>
              <v-btn
                @click="deleteBusiness(item)"
                v-if="actionsRoles.includes(user.type)"
                small
                class="ml-3 my-2 white--text"
                color="red darken-4"
                >Eliminar</v-btn
              >
            </div>
          </template>

          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              >
              </v-skeleton-loader>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="modalNewBusiness"
      persistent
      scrollable
      max-width="1000px"
    >
      <new-business
        :operationType="operationType"
        :businessOriginal="businessToClone"
        :key="randomKey"
        @cancel="modalNewBusiness = false"
        @success="handleCreate"
      >
      </new-business>
    </v-dialog>

    <v-dialog v-model="modalFeaturedBusinesses" max-width="800px">
      <businesses-states
        :key="`states-${randomKey}`"
        :businesses="businesses"
        @cancel="modalFeaturedBusinesses = false"
        @success="handleClone"
      >
      </businesses-states>
    </v-dialog>

    <v-dialog
      v-model="modalAddChildren"
      ref="childrenDialog"
      handleChildren
      max-width="70%"
    >
      <add-children-businesses
        :businesses="businesses"
        :businessFather="businessFather"
        :key="`children-${randomKey}`"
        @cancel="modalAddChildren = false"
        @success="handleChildren"
      >
      </add-children-businesses>
    </v-dialog>

    <v-dialog max-width="400" v-model="modalDelete" v-if="itemToDelete">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ itemToDelete.shortName }}</span
          >?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            :loading="saving"
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from '@/firebase';
import Lottie from '../../../components/Lottie';
import * as animationData from '../../../assets/ochoColor.json';
import moment from 'moment-timezone';
import 'moment/locale/es';
moment.locale('es');
import { mapState, mapActions } from 'vuex';
import newBusiness from './new-business';
import addChildrenBusinesses from './add-children-businesses';
import businessesStates from './businesses-states';
import mobile from 'is-mobile';
import ledgerMixin from '@/mixins/ledgerMixin';

import axios from 'axios';

export default {
  components: {
    Lottie,
    newBusiness,
    addChildrenBusinesses,
    businessesStates,
  },
  mixins: [ledgerMixin],
  data() {
    return {
      banks: [
        { value: 1, text: 'Banco Central de Honduras' },
        { value: 2, text: 'Banco Atlántida' },
        { value: 3, text: 'Banco Ficohsa CB' },
        { value: 4, text: 'Banco de Trabajadores' },
        { value: 5, text: 'Banco de Occidente' },
        { value: 6, text: 'Banco de Honduras' },
        { value: 7, text: 'Banco Hondureño del Cafe (Banhcafe)' },
        { value: 8, text: 'Banco del País (Banpais)' },
        { value: 9, text: 'Banco Lafise' },
        { value: 10, text: 'Banco Ficensa' },
        { value: 11, text: 'BAC Honduras' },
        { value: 12, text: 'Banco Promerica' },
        { value: 13, text: 'Banco Ficohsa' },
        { value: 14, text: 'Banco Davivienda' },
        { value: 15, text: 'BanRural' },
        { value: 16, text: 'Banco Azteca' },
        { value: 17, text: 'Banco Nacional de Desarrollo Agricola( Banadesa)' },
        { value: 18, text: 'Financiera CrediQ' },
        { value: 19, text: 'Banco Popular' },
      ],
      randomKey: 0,
      selectBusiness: true,
      saving: false,
      itemSelected: null,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      anim: null,
      animationSpeed: 1.1,
      businesses: [],
      executives: [],
      selectedExecutive: '',
      cities: [],
      selectedCity: '',
      selectedCities: [],
      snackbarText: '',
      loading: true,
      snackbar: false,
      modalDelete: false,
      itemToDelete: null,
      actionsRoles: ['admin', 'sudo', 'AC', 'EFCM'],
      headers: [
        {
          value: 'imagen',
        },
        {
          text: 'ID del comercio',
          value: 'businessId',
          class: 'nowrap',
          sortable: false,
        },
        {
          text: 'Nombre',
          value: 'shortName',
          width: '200px',
          class: 'nowrap',
        },

        {
          text: 'Teléfono',
          value: 'phone',
          width: '100px',
          class: 'nowrap',
        },

        {
          text: 'Destacado',
          value: 'featured',
          class: 'nowrap',
          roles: ['sudo', 'admin', 'AC', 'EFCM', 'AF'],
        },
        {
          text: 'Es agrupador',
          value: 'isFather',
          class: 'nowrap',
          roles: ['sudo', 'admin', 'AC', 'EFCM', 'AF'],
        },
        {
          text: 'Activo por ocho',
          value: 'ochoActive',
          class: 'nowrap',
          roles: ['sudo', 'admin', 'AC', 'EFCM', 'AF'],
        },

        {
          text: 'Activo',
          value: 'active',
          class: 'nowrap',
          roles: ['sudo', 'admin', 'AC', 'EFCM', 'AF'],
        },
        {
          text: 'Próximamente',
          value: 'isComingSoon',
          class: 'nowrap',
          roles: ['sudo', 'admin', 'AC', 'EFCM', 'AF'],
        },
        {
          text: 'Cerrado por hoy',
          value: 'closed',
          class: 'nowrap',
          roles: ['sudo', 'admin', 'AC', 'EFCM', 'AF'],
        },
        {
          text: 'Forzar cerrado',
          value: 'isForceClosed',
          class: 'nowrap',
          roles: ['sudo', 'admin', 'AC', 'EFCM', 'AF'],
        },
        {
          text: 'Comercio congestionado',
          value: 'isCongested',
          class: 'nowrap',
          roles: ['sudo', 'admin', 'AC', 'EFCM', 'AF'],
        },
        {
          text: 'Modo tester',
          value: 'isTestMode',
          class: 'nowrap',
          roles: ['sudo', 'admin', 'AC', 'EFCM', 'AF'],
        },
        {
          text: 'Envío gratis',
          value: 'isFreeDelivery',
          class: 'nowrap',
          roles: ['sudo', 'admin', 'AC', 'EFCM', 'AF'],
        },
        {
          text: 'Creado',
          value: 'createdAt',
          width: '180px',
          class: 'nowrap',
          roles: ['sudo', 'admin', 'ACC', 'AC', 'EFCM', 'AF'],
        },
        {
          value: 'options',
          sortable: false,
          align: 'end',
          width: '450px',
          roles: ['sudo', 'admin', 'ACC', 'AC', 'EFCM', 'AF'],
        },
      ],
      modalNewBusiness: false,
      modalCloneBusiness: false,
      businessToClone: {},
      modalAddChildren: false,
      businessFather: {},
      operationType: '',
      modalFeaturedBusinesses: false,
      categories: [],
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return 'no disponible';
      return moment(timestamp.toDate()).tz('America/Tegucigalpa').format('LL');
    },
  },
  computed: {
    ...mapState(['user']),
    getHeader() {
      return this.headers.filter(
        (item) =>
          !item.roles || (item.roles && item.roles.includes(this.user.type))
      );
    },
    filterBusinessesByCity() {
      this.loading = true;
      let businesses = Object.assign([], this.businesses);

      if (this.selectedCity) {
        businesses = businesses.filter((business) =>
          this.selectedCity.includes(business.city)
        );
      }

      if (this.selectedExecutive) {
        businesses = businesses.filter(
          (business) => this.selectedExecutive == business.executiveId
        );
      }
      this.loading = false;

      return businesses;
    },
    isMobile() {
      return mobile();
    },
  },

  methods: {
    ...mapActions(['addBusiness', 'addCorporative']),

    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },

    async toExcel() {
      let headers = [
        {
          text: 'Id del comercio',
          value: 'id',
        },
        {
          text: 'Nombre del comercio',
          value: 'name',
        },
        {
          text: 'Teléfono',
          value: 'phone',
        },
        {
          text: 'Activo',
          value: 'active',
        },
        {
          text: 'Destacado',
          value: 'featured',
        },
        {
          text: 'Activo por Ocho',
          value: 'ochoActive',
        },

        {
          text: 'Próximamente',
          value: 'isComingSoon',
        },
        {
          text: 'Cerrado por hoy',
          value: 'closed',
        },
        {
          text: 'Modo tester',
          value: 'isTestMode',
        },

        {
          text: 'Ejecutivo encargado',
          value: 'executive',
        },
        {
          text: 'Categorias',
          value: 'categories',
        },
        // {
        //   text: "Origen",
        //   value: "origen",
        // },

        // {
        //   text: "Tipo de transacción",
        //   value: "type",
        // },
        {
          text: 'Fecha de creación',
          value: 'createdAt',
        },
        {
          text: 'Es agrupador',
          value: 'isFather',
        },
        {
          text: 'Ciudades',
          value: 'cities',
        },
        {
          text: 'Servicios',
          value: 'services',
        },
        {
          text: 'RTN',
          value: 'tinNumber',
        },
        {
          text: 'Comisión',
          value: 'comission',
        },
        {
          text: 'Comercio congestionado',
          value: 'isCongested',
        },
        {
          text: 'Bancos',
          value: 'businessBanks',
        },
        {
          text: 'Número de cuenta',
          value: 'businessBanksNumber',
        },
        {
          text: 'Cerrado permanente',
          value: 'isForceClosed',
        },
        {
          text: 'Dilo absorbe ISV',
          value: 'ochoPayTax',
        }
      ];

      // console.log(this.cities);
      // console.log(this.filterBusinessesByCity);
      let data = this.filterBusinessesByCity.map((e) => {
        // console.log(e);
        let executive = this.executives.find((p) => p['.key'] == e.executiveId);

        let categories = this.categories.filter(
          (c) => e.ochoCategories && e.ochoCategories.includes(c['.key'])
        );

        let categoriesName = categories.map((c) => {
          return c.name;
        });

        let cities = this.cities.filter(
          (city) => e.cities && e.cities.includes(city['.key'])
        );

        let cityNames = cities.map((c) => {
          return c.name;
        });

        let businessBanks = '';
        let businessBanksNumber = '';
        /*
        * Se obtienen los nombres de los bancos y los números de cuenta
        * de los comercios
        * */
        if (e.bankAccounts?.length) {
          businessBanks = e.bankAccounts
            .map(account => this.banks.find(b => b.value === account.bank)?.text || '')
            .join('; ');

          businessBanksNumber = e.bankAccounts
            .map(account => account.number)
            .join('; ');
        }
        return {
          name: e.shortName,
          id: e['.key'],
          active: e.active ? 'Si' : 'No',
          phone: e.phone,
          featured: e.featured ? 'Si' : 'No',
          isComingSoon: e.isComingSoon ? 'Si' : 'No',
          ochoActive: e.ochoActive ? 'Si' : 'No',
          closed: e.closed ? 'Si' : 'No',
          isTestMode: e.isTestMode ? 'Si' : 'No',
          executive: executive ? executive.name : '',
          createdAt: moment.unix(e.createdAt.seconds).format('DD/MM/YYYY'),
          categories: categoriesName.join(','),
          isFather: e.isFather ? 'Si' : 'No',
          cities: cityNames.join(','), // join separa los elementos por coma
          tinNumber: e.tin ? String(e.tin) : '', // RTN
          // agrupador
          services:
            e.active && e.qrActive
              ? 'MenuHN, Dilo Delivery'
              : e.active
              ? 'Dilo Delivery'
              : e.qrActive
              ? 'MenuHN'
              : '',
          comission: e.currentPercentage ? e.currentPercentage * 100 + '%' : 0,
          isCongested: e.isCongested ? 'Si' : 'No',
          businessBanks: businessBanks,
          businessBanksNumber: businessBanksNumber,
          isForceClosed: e.isForceClosed ? 'Si' : 'No',
          ochoPayTax: e.ochoPayTax ? 'Si' : 'No',
        };
      });
      // console.log(data);
      let token = await this.getSectionToken();
      this.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_FUNCTIONS_URL}/httpGenerateExcelFile`,
          {
            rows: headers,
            data,
          },
          { headers: { Authorization: `Basic ${token}` }, responseType: 'blob' }
        )
        .then((response) => {
          // console.log(response);
          this.loading = false;

          const { data, headers } = response;
          const fileName = `comercios.xlsx`;

          const blob = new Blob([data], { type: headers['content-type'] });
          let dom = document.createElement('a');
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = 'none';
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.loading = false;
          // console.log(err.response);
          this.snackbarText =
            'Ocurrió un error inesperado, inténtelo nuevamente.';
          this.snackbar = true;
        });
    },
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    async handleFreeDelivery(item) {
      let batchData = [];

      this.loading = true;

      let products = await db
        .collection('products')
        .where('business', 'array-contains', item['.key'])
        .get();

      for (let i = 0; i < products.docs.length; i++) {
        const productId = products.docs[i].id;

        batchData.push({
          reference: db.collection('products').doc(productId),
          data: {
            isFreeDelivery: item.isFreeDelivery ? true : false,
          },
        });
      }

      for (let i = 0; i < batchData.length; i = i + 490) {
        let batch = db.batch();
        let itemsToUpdate = batchData.slice(i, i + 490);

        itemsToUpdate.forEach((item) => {
          batch.update(item.reference, item.data);
        });

        await batch.commit();
        batch = null;
      }

      await db
        .collection('businesses')
        .doc(item['.key'])
        .update({
          isFreeDelivery: item.isFreeDelivery,
          freeShippingProductsIsAvailable: item.isFreeDelivery ? true : false,
        });

      this.loading = false;
      this.snackbarText = 'Envío gratis aplicado a todo el comericio.';
      this.snackbar = true;
    },
    handleClone() {
      this.modalCloneBusiness = false;
      this.snackbarText = 'Comercio clonado exitosamente.';
      this.snackbar = true;
    },
    handleChildren() {
      this.modalAddChildren = false;
      this.snackbarText = 'Comercios asociados exitosamente.';
      this.snackbar = true;
    },
    showAddBusiness() {
      this.modalNewBusiness = true;
      this.randomKey = Math.random();
      this.operationType = 'new';
    },
    clone(item) {
      this.randomKey = Math.random();
      this.businessToClone = item;
      this.operationType = 'clone';
      this.modalNewBusiness = true;
    },
    addChildren(item) {
      this.randomKey = Math.random();
      this.businessFather = item;
      this.modalAddChildren = true;
    },
    handleCreate() {
      this.modalNewBusiness = false;
      // this.getBusinesses();
      this.snackbarText = 'Comercio creado exitosamente.';
      this.snackbar = true;
    },
    deleteBusiness(item) {
      this.itemToDelete = item;
      this.modalDelete = true;
    },
    confirmDelete() {
      this.saving = true;
      let data = {
        deleted: true,
        deletedAt: new Date(),
        deletedBy: this.user['.key'],
      };
      db.collection('businesses')
        .doc(this.itemToDelete['.key'])
        .update(data)
        .then((ref) => {
          this.saving = false;
          this.businesses = this.businesses.filter(
            (item) => item['.key'] != this.itemToDelete['.key']
          );
          this.snackbarText = 'Comercio eliminado correctamente.';
          this.snackbar = true;
          this.modalDelete = false;
          this.itemToDelete = null;
        })
        .catch((err) => {
          this.saving = false;
          this.snackbarText =
            'Ocurrió un error inesperado, inténtelo nuevamente.';
          this.snackbar = true;
        });
    },
    editBusiness(item) {
      this.$root.$children[0].businessListener(item);
      this.addBusiness(item);
      this.addCorporative(null);
      this.snackbarText = 'Comercio agregado al menú';
      this.snackbar = true;

      setTimeout(() => {
        document.getElementById('businessMenu').click();

        this.scroll();
      }, 500);
    },

    scroll() {
      setTimeout(() => {
        let ele = document.getElementsByClassName(
          'v-navigation-drawer__content'
        );

        ele[0].scroll({ top: ele[0].scrollHeight, behavior: 'smooth' });
      }, 500);
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Copiado al portapapeles';
        })
        .catch((err) => console.error('Async: Could not copy text: ', err));
    },
    async getBusinesses(e) {
      this.loading = true;
      if (e && e.length) {
        await this.$binding(
          'businesses',
          db
            .collection('businesses')
            .where('cities', 'array-contains-any', e)
            .where('deleted', '==', false)
        );
      } else {
        this.businesses = [];
      }

      this.loading = false;
    },
    updateCascadeBusiness(businessId, business) {
      db.collection(`cities/${business.cities[0]}/sections`)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let sectionId = doc.id;
            let section = doc.data();

            let items = section.target.filter((doc) => {
              return doc.business == businessId;
            });

            items.forEach((elementUpdate) => {
              elementUpdate.isBusinessActive = business.active;
              elementUpdate.closed = business.closed;
              elementUpdate.isForceClosed = business.isForceClosed;
              elementUpdate.isCongested = business.isCongested;
              elementUpdate.isComingSoon = business.isComingSoon;
            });

            db.collection(`cities/${business.cities[0]}/sections`)
              .doc(sectionId)
              .update({
                target: section.target,
              })
              .catch((error) => {
                throw error;
              });
          });
        })
        .catch(console.error);
    },
    switchControlChanged(data, businessId, business, field) {
      let prevData = Object.assign({}, data);
      prevData[field] = !data[field];

      if (data && businessId) {
        this.saving = true;
        db.collection('businesses')
          .doc(businessId)
          .update(data)
          .then(() => {
            if (
              business.isLobbySection == true ||
              business.isLobbySection == false
            ) {
              this.updateCascadeBusiness(businessId, business);
            }
            this.saving = false;
            this.snackbarText =
              'Información del comercio actualizada correctamente.';
            this.snackbar = true;

            this.createLedger({
              source_id: businessId,
              source_type: 'businesses',
              currentValue: data,
              prevValue: prevData,
            });
          })
          .catch((err) => {
            // console.log(err);
            this.saving = false;
            this.snackbarText =
              'Ocurrió un error inesperado, inténtelo nuevamente.';
            this.snackbar = true;
            business[field] = !business[field];
          });
      }
    },
    showFeaturedBusinesses() {
      this.modalFeaturedBusinesses = true;
    },
  },
  async mounted() {
    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = 'comercio';
    this.$store.state.search = '';

    await this.$binding(
      'executives',
      db.collection('appUsers').where('type', '==', 'AC')
    );

    await this.$binding('categories', db.collection('categories'));

    await db
      .collection('cities')
      .get()
      .then((response) => {
        response.forEach((item) => {
          let city = item.data();
          city['.key'] = item.id;
          this.cities.push(city);
        });
      });

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.options-flex {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
</style>

<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
        snackbarText
      }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
          :options="defaultOptions"
          :height="150"
          :width="150"
          v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="5" md="5">
        <h1>Usuarios del Panel Administrativo</h1>
      </v-col>

      <v-col cols="5" md="5">
        <v-select
            v-model="selectedRol"
            :items="roles"
            prepend-icon="fa-user"
            label="Filtrar por rol de usuario"
            item-text="name"
            item-value="value"
            aria-autocomplete="false"
            hide-details
            filled
            rounded
            :allow-overflow="false"
            clearable
        >
        </v-select>
      </v-col>

      <v-col
          style="display: flex; justify-content: flex-end"
          cols="2"
          sm="2"
          md="2"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                color="rgba(0, 0, 0, 0.6)"
                fab
                v-on="on"
                class="ml-2"
                dark
                small
                :elevation="0"
                :loading="loading"
                @click="reportDialog = true"
            >
              <v-icon x-large dark>fa-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Exportar .xlsx</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-row justify="end">
          <v-btn
              color="primary"
              class="ma-2 white--text"
              @click="modalAddUser = true"
              :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nuevo usuario
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="getUsers"
            :search="$store.state.search"
            :items-per-page="15"
            :loading="loading"
            sort-by="name"
            :sort-asc="true"
            item-key=".key"
            fixed-header
            :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                  style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                />
                <p
                    :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <v-switch
                @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
                v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <v-tooltip v-if="item.email" left>
              <template v-slot:activator="{ on }">
                <v-chip
                    :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.email)"
                >{{ item.email }}
                </v-chip
                >
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            {{ item.type | filterRoleName }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="editUser(item)" small color="primary" class="mr-3"
            >Editar usuario
            </v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
        persistent
        v-if="userSelected"
        v-model="modalEditUser"
        max-width="800px"
    >
      <edit-user
          type="panel"
          @cancel="modalEditUser = false"
          @notFound="userNotFound()"
          @success="updateSuccess"
          :key="userSelected['.key']"
          :user="userSelected"
          :panelRoles="roles"
      ></edit-user>
    </v-dialog>

    <v-dialog
        v-if="modalAddUser"
        persistent
        v-model="modalAddUser"
        max-width="800px"
    >
      <add-user
          type="panel"
          @cancel="modalAddUser = false"
          @success="userCreated"
          :panelRoles="roles"
      ></add-user>
    </v-dialog>

    <v-dialog
        scrollable
        persistent
        v-if="reportDialog"
        v-model="reportDialog"
        max-width="600px"
    >
      <report @cancel="reportDialog = false" type="AF"/>
    </v-dialog>
  </v-container>
</template>

<script>
import {db} from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";

import editBusinesses from "./edit-businessOwner-businesses";
import editUser from "./edit-user";
import addUser from "./create-user";
import {mapState} from "vuex";
import report from "./reports.vue";

export default {
  name: "users-sudo",
  components: {
    lottie: Lottie,
    editBusinesses,
    editUser,
    addUser,
    report,
  },
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      reportDialog: false,
      modalEditBusinesses: false,
      userSelected: null,
      snackbarText: "",
      timer: null,
      itemToEdit: {},
      users: [],
      sudoUsers: [],
      selectedRol: null,
      roles: [
        {value: "admin", name: "Administrador"},
        {value: "AF", name: "Agente Facilitador"},
        {value: "AC", name: "Agente Comercial"},
        {value: "ACC", name: "Agente Cuenta Contable"},
        {value: "EFCM", name: "Agente Mercadeo"},
        {value: "sudo", name: "Super usuario"},
        {value: "driver", name: "Jefe de Motoristas"},

      ],
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      modalAddUser: false,
      modalEditUser: false,
      anim: null,
      animationSpeed: 1.1,
      headers: [
        {
          value: "imagen",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Correo",
          value: "email",
        },
        {
          text: "Rol",
          value: "type",
        },
        {
          text: "Activo",
          value: "active",
        },

        {
          value: "options",
          width: "400px",
          align: "end",
        },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
    getUsers() {
      if (this.selectedRol) {
        return this.users.filter((e) => e.type == this.selectedRol);
      } else {
        return this.users;
      }
    },
  },
  filters: {
    filterRoleName(e) {
      let roles = {
        AF: "Agente Facilitador",
        sudo: "Súper Usuario",
        businessOwner: "Socio Comercial",
        ACC: "Agente Cuenta Contable",
        AC: "Agente Comercial",
        admin: "Administrador",
        EFCM: "Agente Mercadeo",
      };

      return roles[e] ? roles[e] : "Desconocido";
    },
  },

  methods: {
    firstLetter(name) {
      return name != undefined ? name.split("")[0] : "";
    },

    userNotFound() {
      this.snackbarText = "Usuario no encontrado.";
      this.snackbar = true;
      this.modalEditUser = false;
      this.userSelected = null;
    },

    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    editUser(item) {
      this.userSelected = item;
      this.modalEditUser = true;
    },

    userCreated() {
      this.modalAddUser = false;
      this.snackbar = true;
      this.snackbarText = "Usuario agregado exitosamente.";
    },

    editBusinesses(item) {
      this.userSelected = item;
      this.modalEditBusinesses = true;
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
          () => {
            this.snackbar = true;
            this.snackbarText = "Copiado al portapapeles";
          },
          (err) => {
            console.error("Async: Could not copy text: ", err);
          }
      );
    },

    updateSuccess(user) {
      this.modalEditBusinesses = false;
      this.modalEditUser = false;
      this.snackbar = true;
      this.snackbarText = "Información del usuario actualizada correctamente.";
    },

    switchControlChanged(data, userId, user) {
      if (data && userId) {
        this.saving = true;
        db.collection("appUsers")
            .doc(userId)
            .update({
              active: data.active,
              modifiedAt: new Date(),
              modifiedBy: this.user[".key"],
            })
            .then((ref) => {
              this.saving = false;
              this.snackbarText = "Información actualizada correctamente.";
              this.snackbar = true;
            })
            .catch((err) => {
              this.saving = false;
              this.snackbarText =
                  "Ocurrió un error inesperado, inténtelo nuevamente.";
              this.snackbar = true;
              user.active = !user.active;
            });
      }
    },
  },

  async mounted() {
    this.$store.commit("setSearchTerm", "usuario");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    let allowedUsers = {
      sudo: ["sudo", "admin", "AC", "ACC", "EFCM", "AF"],
      admin: ["AC", "ACC", "EFCM", "AF"],
    };

    await this.$binding(
        "users",
        db
            .collection("appUsers")
            .where("type", "in", allowedUsers[this.user.type])
            .where("active", "==", true)
    );

    if (this.user.type == "admin") {
      this.roles = this.roles.filter(
          (i) => i.value != "sudo" && i.value != "admin"
      );
    }

    this.loading = false;
  },
};
</script>